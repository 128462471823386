var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-container",
    {
      staticClass: "container",
      attrs: { "grid-list-md": "", "text-xs-center": "" }
    },
    [
      _c(
        "v-layout",
        { attrs: { row: "", wrap: "" } },
        [
          _c(
            "v-flex",
            { staticClass: "ma-5", attrs: { md12: "", xs12: "" } },
            [
              _c(
                "v-card",
                { staticClass: "card pa-4" },
                [
                  _c(
                    "div",
                    {
                      staticClass:
                        "header d-flex justify-space-between align-center"
                    },
                    [
                      _c(
                        "h1",
                        {
                          staticClass: "secondary-text text-lg-left form-title"
                        },
                        [_vm._v(" Projects ")]
                      ),
                      _c(
                        "div",
                        [
                          _c(
                            "v-btn",
                            {
                              staticClass: "white--text",
                              attrs: { "x-large": "", color: "blue-grey" },
                              on: {
                                click: function($event) {
                                  _vm.projectDialogItem = null
                                  _vm.projectDialog = true
                                  _vm.toEdit = false
                                }
                              }
                            },
                            [
                              _c(
                                "v-icon",
                                { attrs: { small: "", left: "", dark: "" } },
                                [_vm._v("mdi-home-plus-outline")]
                              ),
                              _vm._v(" Aanmaken ")
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ]
                  ),
                  _c(
                    "v-card",
                    { attrs: { flat: "", elevation: 0 } },
                    [
                      _c(
                        "v-card-title",
                        { staticClass: "d-flex justify-space-between px-0" },
                        [
                          _c("filter-data-dialog-component", {
                            staticClass: "filter--container",
                            attrs: {
                              acceptedFilters: _vm.acceptedFilters,
                              uniqueFilterStoreName: "filter$projectList",
                              showFilters: false,
                              showSearchBar: true
                            },
                            on: { filterData: _vm.setFilterOnList }
                          })
                        ],
                        1
                      ),
                      _c(
                        "v-data-table",
                        {
                          attrs: {
                            headers: _vm.headers,
                            items: _vm.projects,
                            search: _vm.search,
                            options: _vm.options,
                            "server-items-length": _vm.totalCount,
                            loading: _vm.isLoading,
                            "footer-props": {
                              "items-per-page-options": [5, 10, 15]
                            }
                          },
                          on: {
                            "update:options": function($event) {
                              _vm.options = $event
                            },
                            "update:page": _vm.setSkip,
                            "update:items-per-page": _vm.setLimit,
                            "update:sort-by": _vm.setSort,
                            "update:sort-desc": _vm.setSort
                          },
                          scopedSlots: _vm._u([
                            {
                              key: "header",
                              fn: function(ref) {
                                var props = ref.props
                                return [
                                  _c(
                                    "tr",
                                    { staticClass: "grey lighten-3" },
                                    _vm._l(props.headers, function(header) {
                                      return props.headers
                                        ? _c("th", { key: header.text })
                                        : _vm._e()
                                    }),
                                    0
                                  )
                                ]
                              }
                            },
                            {
                              key: "item.edit",
                              fn: function(ref) {
                                var item = ref.item
                                return [
                                  _c(
                                    "td",
                                    [
                                      !_vm.canSee
                                        ? _c(
                                            "v-btn",
                                            {
                                              attrs: { icon: "" },
                                              on: {
                                                click: function($event) {
                                                  _vm.projectDialogItem = Object.assign(
                                                    {},
                                                    item,
                                                    item.edit
                                                  )
                                                  _vm.projectDialog = true
                                                  _vm.toEdit = true
                                                }
                                              }
                                            },
                                            [
                                              _c(
                                                "v-icon",
                                                {
                                                  attrs: {
                                                    medium: "",
                                                    dark: ""
                                                  }
                                                },
                                                [
                                                  _vm._v(
                                                    "mdi-home-edit-outline"
                                                  )
                                                ]
                                              )
                                            ],
                                            1
                                          )
                                        : _vm._e()
                                    ],
                                    1
                                  )
                                ]
                              }
                            }
                          ])
                        },
                        [
                          _c(
                            "template",
                            { slot: "no-data" },
                            [
                              _c(
                                "v-alert",
                                {
                                  attrs: {
                                    value: true,
                                    color: "grey",
                                    icon: "mdi-warn"
                                  }
                                },
                                [_vm._v(" Sorry, geen projecten gevonden ")]
                              )
                            ],
                            1
                          )
                        ],
                        2
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _vm.projectDialog
        ? _c(
            "v-dialog",
            {
              attrs: { "max-width": "700px", scrollable: "", persistent: "" },
              model: {
                value: _vm.projectDialog,
                callback: function($$v) {
                  _vm.projectDialog = $$v
                },
                expression: "projectDialog"
              }
            },
            [
              _c("project-dialog", {
                attrs: {
                  item: _vm.projectDialogItem,
                  edit: _vm.toEdit,
                  permission: _vm.getRights.PROJECTS_INFO
                },
                on: {
                  save: _vm.saveProject,
                  close: function($event) {
                    _vm.projectDialog = false
                  }
                }
              })
            ],
            1
          )
        : _vm._e(),
      _c(
        "v-dialog",
        {
          attrs: { "max-width": "400px" },
          model: {
            value: _vm.confirmDeleteDialog,
            callback: function($$v) {
              _vm.confirmDeleteDialog = $$v
            },
            expression: "confirmDeleteDialog"
          }
        },
        [
          _vm.confirmDeleteDialog
            ? _c("confirm-delete", {
                attrs: { item: _vm.confirmDeleteDialogItem },
                on: {
                  cancel: function($event) {
                    _vm.confirmDeleteDialogItem = null
                    _vm.confirmDeleteDialog = false
                  },
                  delete: _vm.deleteClicked
                }
              })
            : _vm._e()
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }