var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-container",
    {
      staticClass: "container",
      attrs: { "grid-list-md": "", "text-xs-center": "" }
    },
    [
      _c(
        "v-flex",
        { staticClass: "ma-5 mb-0", attrs: { md12: "", xs12: "" } },
        [
          _c("v-card", { staticClass: "card pa-4" }, [
            _c(
              "div",
              { staticClass: "header" },
              [
                _c(
                  "h1",
                  { staticClass: "secondary-text text-lg-left form-title" },
                  [_vm._v(" Mijn profiel "), _c("v-spacer")],
                  1
                ),
                _c(
                  "v-tabs",
                  {
                    staticClass: "tabs",
                    attrs: { "show-arrows": "" },
                    model: {
                      value: _vm.active,
                      callback: function($$v) {
                        _vm.active = $$v
                      },
                      expression: "active"
                    }
                  },
                  [
                    _c("v-tabs-slider"),
                    _c(
                      "v-tab",
                      {
                        attrs: { href: "#personal", "active-class": "active" }
                      },
                      [_vm._v("Persoonlijke informatie")]
                    ),
                    _c(
                      "v-tab",
                      {
                        attrs: { href: "#password", "active-class": "active" }
                      },
                      [_vm._v("Wachtwoord")]
                    )
                  ],
                  1
                )
              ],
              1
            )
          ])
        ],
        1
      ),
      _c("div", { staticClass: "grid text-xs-center" }, [
        _c(
          "div",
          { attrs: { id: "profile-form" } },
          [
            _c(
              "v-tabs-items",
              {
                model: {
                  value: _vm.active,
                  callback: function($$v) {
                    _vm.active = $$v
                  },
                  expression: "active"
                }
              },
              [
                _c(
                  "v-tab-item",
                  { attrs: { value: "personal" } },
                  [
                    _vm.userLoaded
                      ? _c("personal", { attrs: { user: _vm.user } })
                      : _vm._e()
                  ],
                  1
                ),
                _c(
                  "v-tab-item",
                  { attrs: { value: "password" } },
                  [
                    _vm.userLoaded
                      ? _c("password", {
                          attrs: { user: _vm.user },
                          on: { save: _vm.changePass }
                        })
                      : _vm._e()
                  ],
                  1
                )
              ],
              1
            )
          ],
          1
        )
      ])
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }